// extracted by mini-css-extract-plugin
export var description = "index-module--description--cf077";
export var descriptionContainer = "index-module--description-container--c51d5";
export var image = "index-module--image--32d09";
export var item = "index-module--item--8e8d3";
export var itemEven = "index-module--item-even--4ccf3";
export var itemOdd = "index-module--item-odd--8a58b";
export var mainImageContainer = "index-module--main-image-container--86645";
export var mainItems = "index-module--main-items--f2cfd";
export var mediaContainer = "index-module--media-container--33e52";
export var mediaInquiriesLink = "index-module--media-inquiries-link--dba48";
export var readMoreButton = "index-module--read-more-button--6564e";
export var textContainer = "index-module--text-container--becdb";
export var title = "index-module--title--1304d";
export var titleArticle = "index-module--title-article--67781";