import { Link, graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { object } from 'prop-types';
import { Articles } from '../../components/media';
import * as styles from './index.module.scss';

function Media({ data }) {
  const { pageData } = data;
  const mainArticles = pageData.featuredMediaArticles;
  const mainArticlesIds = mainArticles.map((item) => item.id);
  const { mediaPageSubtitle, mediaPageTitle } = pageData;
  let moreArticles = data.allContentfulPageMediaDetail?.nodes;
  moreArticles = moreArticles.filter((item) => !mainArticlesIds.includes(item.id));
  // Just 5 elements are show at maximum
  moreArticles = moreArticles.slice(0, 5);
  return (
    <div className={styles.mediaContainer}>
      <div className={styles.mainItems}>
        <h1 className={styles.title}>{mediaPageTitle}</h1>
        <Link className={styles.mediaInquiriesLink} to="/media-inquiries">Media inquiries</Link>
        <div className={styles.mainImageContainer}>
          {mainArticles.map((item, i) => (
            <div
              className={i % 2 === 0 ? styles.itemEven : styles.itemOdd}
              key={item.title}
            >
              {item.detailImage && (
              <>
                <GatsbyImage
                  className={styles.image}
                  image={getImage(item.detailImage.image.gatsbyImage)}
                  loading="eager"
                  alt={item.detailImage.altText}
                />
                <div className={styles.textContainer}>
                  <Link className={styles.titleArticle} to={`/media${item.uri}`}>{item.title}</Link>
                  <div className={styles.descriptionContainer}>
                    <span className={styles.description}>{item.description?.description}</span>
                    <Link
                      to={`/media${item.uri}`}
                      className={styles.readMoreButton}
                    >
                      {' '}
                      Read more
                    </Link>
                  </div>
                </div>
              </>
              )}
            </div>
          ))}
        </div>
      </div>
      <Articles
        title={mediaPageSubtitle}
        articles={moreArticles}
        showTitle
      />
    </div>
  );
}

export const query = graphql`
  {
    allContentfulPageMediaDetail(limit: 8, sort: {fields: date, order: DESC}) {
      nodes {
        id
        title
        uri
        description {
          description
        }
        date
        seo {
          metaDescription
          pageTitle
        }
        detailImage {
          title
          image {
            gatsbyImage(width: 260, placeholder: NONE, layout: FIXED)
          }
          altText
        }
      }
    }
    pageData: contentfulPageMediaConfig(id: {eq: "2db7e1ea-f396-5883-948a-b2cac81d7916"}) {
      id
      featuredMediaArticles {
        id
        title
        uri
        description{
          description
        }
        detailImage {
          image {
            gatsbyImage(width: 1000, placeholder: NONE, layout: FULL_WIDTH)
          }
          altText
        }
      }
      mediaPageSubtitle
      mediaPageTitle
      seo {
        metaDescription
        pageTitle
      }
    }
  }
`;

Media.propTypes = {
  data: object,
};

Media.defaultProps = {
  data: {},
};

export default Media;
export { Head } from '../../components/seo/seo';
